import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
  padding: 5px;
  flex-direction: column;
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(150%); 
    opacity: 0; 
  }
  to {
    transform: translateX(0); 
    opacity: 1;
  }
`;

const Card = styled.div`
  width: 80%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 5px;
  padding: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  animation: ${slideInFromLeft} 1s ease-in-out forwards;
  margin: 10px;
  box-shadow: 0 5px 50px 0 rgba(31, 38, 135, 0.1);
  height: 230px;
  opacity: 0; 


  &:nth-child(1) {
    animation-delay: 0s; 
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    height: auto;
    animation-delay: 0.4s; 
  }
`;
const Title = styled.h1`
   color: #c9c7c6;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 10px;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem; 
`;

const ToolList = styled.div`
 color: #c9c7c6;
  display: grid;
  grid-template-columns: ${({ itemCount }) => (itemCount > 6 ? '1fr 1fr' : '1fr')}; 
  gap: 8px;
  text-align: center;
  width: 100%; 
`;

const LanguageList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
     color: #c9c7c6;
`;

const ToolItem = styled.p`
  margin: 0;
  padding: 4px;
  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: 1px;
  
  
`;

const LanguageLevel = styled.p`
  margin: 4px 0 0;
  font-size: 0.9em;
  color: #b0b0b0;
`;
const KnowledgeExpanded = () => {
  const languages = [
    { name: 'English', level: 'B2' },
    { name: 'Spanish', level: 'Native' },
    { name: 'French', level: 'A1' },
  ];

  return (
    <Container>
      <Card>
        <Title>Learning & Certifications</Title>
        <ToolList itemCount={4}>
          {['Expert in Offensive Cybersecurity', 'Advanced Education Program in Cybersecurity', "Bachelor's Degree in Systems", "Full Stack - Web Developer"].map((title, index) => (
            <ToolItem key={index}> - {title}</ToolItem>
          ))}
        </ToolList>
      </Card>
      <Card>
        <Title>Tools</Title>
        <ToolList itemCount={12}>
          {['Qualys', 'Nmap', 'Burpsuite', 'Metasploit', 'Aircrack-ng', 'Hydra', 'Nessus', 'Nikto', 'SQLmap', 'Netcat', 'Sherlock', 'Shodan'].map((title, index) => (
            <ToolItem key={index}>- {title}</ToolItem>
          ))}
        </ToolList>
      </Card>
      <Card>
        <Title>Languages</Title>
        <LanguageList itemCount={languages.length}>
          {languages.map((lang, index) => (
            <ToolItem key={index}>
              {lang.name}
              <LanguageLevel>{lang.level}</LanguageLevel>
            </ToolItem>
          ))}
        </LanguageList>
      </Card>
    </Container>
  );
};

export default KnowledgeExpanded;
