import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";





const Contenedor = styled.nav`
    position: absolute;
    bottom: 50px ;
    position: fixed;
    right: 2%;
  
    @media (min-width:20.00em) {        
        
    
  }
  @media (min-width:37.500em) {
    right: 2%;
    
  }
  @media(min-width:44rem) {
    /* right: 10%; */
    border-left: 2px solid rgba(255, 255, 255, 0.15);
    padding-left: 15px;
         
  }
  @media(min-width:61.93em) {
   right: 1%;
  }  
  @media(min-width:70.93em) {
   right: 10%;
  }  
   
`


const StyledList = styled.ul`
    display:flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    align-items: center;
    text-decoration: none;
    
`

const StyledListItem = styled.li`

`

const StyledLink = styled.a`
    text-decoration: none;
    color:#333;
`



const Icono = styled(FontAwesomeIcon)`
   transition: transform 0.5s ease;
   font-size: 1.8rem;
   color:#c9c7c6;
    
   :hover{
    transform: scale(1.1);
    color: white;
   }
   @media (min-width: 992px) {
    font-size: 2rem;
  }
   
  
`



const Social = () => {


    return (<Contenedor>

        <StyledList >
            <StyledListItem  >
                <StyledLink href="https://www.linkedin.com/in/mateo-rufino/" >
                    <Icono icon={faLinkedin} />
                </StyledLink>
            </StyledListItem>

            <StyledListItem>
                <StyledLink href="https://github.com/materufino?tab=repositories">
                    <Icono icon={faGithub} />
                </StyledLink>
            </StyledListItem>
        </StyledList>


    </Contenedor>)
}

export default Social;


