import React from "react"
import AboutMe from "./Pequeños/AboutMe"
import ContactMe from "./Pequeños/ContactMe"
import NuevaPortada from "./Pequeños/NuevaPortada"
import Social from "./Pequeños/Social"
import styled from "styled-components"


const All = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
`;
const Home = () => {


    return (
        <All>
            <Social />
            <NuevaPortada />
            <AboutMe />
            <ContactMe />
        </All>)
}

export default Home