import anforas from "../../Assets/anforas.png";
import guardianes from "../../Assets/guardianes.png";
import uriel from "../../Assets/uriel.png"
import lacelina from "../../Assets/lacelina.png"

const ProyectosWeb = [
    {
        titulo: "Los guardianes de la chispa",
        url: "",
        imagen: guardianes,
        descripcion: "Capstone project for the end of the Full Stack Web Development Bootcamp. A CRM was designed for a blacksmith in the Visigoth kingdom, where an administrator can log in to create orders or employees with their respective ranks and tasks.",
        tecnologias: ["react", "mysql", "expressJs"]
    },
    {
        titulo: "La Celina Country House",
        url: "https://www.casadecampolacelina.com",
        imagen: lacelina,
        descripcion: "Presenting a beautiful country house with charming photos of its facilities, amenities, and services. Visitors can enjoy the rooms, outdoor activities, relax in the Australian water tank, and also book for special events.",
        tecnologias: ["react"]
    },
    {

        titulo: "Ánforas de Atenea",
        url: 'https://www.anforasdeatenea.com',
        imagen: anforas,
        descripcion: "First web version for the company, featuring their story, showcasing their products, and displaying their photo gallery",
        tecnologias: ["react"]
    },
    {
        titulo: "Uriel Villalva",
        url: "https://www.urielvillalva.com",
        imagen: uriel,
        descripcion: "Portfolio for photographer and filmmaker Uriel Villalva, where he can showcase his work in both music videos and photography, including a contact section with his social media links.",
        tecnologias: ["react"]
    }


]
export default ProyectosWeb;