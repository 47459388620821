import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Proyectos from "../../Contenido/Proyectos";
import ProyectosWeb from "../../Contenido/ProyectosWeb";

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
   @media (min-width: 992px) {
    
  }

`;

const fadeOut = keyframes`
  from {
    
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-250%);
    opacity: 0;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(250%);
    opacity: 0;
  }
  to {
    
    transform: translateX(0);
    opacity: 1;
  }
`;

const Card = styled.div`
  opacity: 0;
  width: 350px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 5px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;
  box-shadow: 0 5px 25px 0 rgba(31, 38, 135, 0.1);
  color: #c9c7c6;
  height: 400px;
  cursor: pointer;
  transition: all 1s;

  ${({ isExiting }) =>
    isExiting
      ? css`
          animation: ${fadeOut} 0ms ease-in-out forwards;
        `
      : css`
          animation: ${slideInFromLeft} 1s ease-in-out forwards;
        `}

  animation-delay: ${({ delay }) => delay}ms;

  &:hover {
    background-color: #343955;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.39);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.39);
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.39);
  }
`;

const Title = styled.h1`
  text-align: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  padding: 10px;
  margin: 0 auto;
  min-height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 1.5rem;
  width: 90%;
  font-family: 'IBM Plex Sans', sans-serif;
  text-transform: uppercase;
`;

const Description = styled.p`
  width: 90%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  margin: 10px auto;
  text-align: justify;
  line-height: 20px;
  min-height: 100px;
`;

const Img = styled.img`
  width: 55%;
  margin: auto;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const ProjectsExpanded = ({ setIsSubExpanded, handleShrinkToOriginal }) => {
  const [showWebProjects, setShowWebProjects] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [animationLocked, setAnimationLocked] = useState(false);

  const proyectos = showWebProjects ? ProyectosWeb : Proyectos;

  const handleCardClick = (titulo) => {
    if (titulo === "Web Development Projects" && !animationLocked) {
      setAnimationLocked(true); // Lock para evitar múltiples clics mientras la animación está en progreso
      setIsExiting(true);
      setTimeout(() => {
        setIsExiting(false);
        handleShrinkToOriginal();
        setShowWebProjects(true);
        setAnimationLocked(false);
      }, 100);
    } else if (!animationLocked) {
      alert('This feature is not available yet.');
    }
  };

  return (
    <Container>
      {proyectos.map((proyecto, index) => (
        <Card
          key={index}
          delay={index * 100}
          isExiting={isExiting && !showWebProjects}
          onClick={() => handleCardClick(proyecto.titulo)}
        >
          <Title>{proyecto.titulo}</Title>
          <Description>{proyecto.descripcion}</Description>
          <Img loading="lazy" src={proyecto.imagen} alt={proyecto.titulo} />
        </Card>
      ))}
    </Container>
  );
};

export default ProjectsExpanded;
