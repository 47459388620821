import styled from "styled-components"
import React from 'react';


const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;  
  padding: 5px;
  
`;
const Card = styled.div`
  min-width: 270px;
  min-height: 200px; /* Asegura que el Card ocupe la altura total que necesitas */
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 5px 50px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-style: solid;
  border-radius: 1px;
  border-width: 2px; 
  border-color: #4B527B;
  padding: 1.5rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 2ms;
  :hover{
    -webkit-box-shadow: 0px 0px 2px 0px rgba(255,255,255,0.39);
    -moz-box-shadow: 0px 0px 2px 0px rgba(255,255,255,0.39);
    box-shadow: 0px 0px 2px 0px rgba(255,255,255,0.39);
    transform: scaleX(1.01);
  }
  
`;

const Title = styled.h1`
  color: #c9c7c6;
  font-size: 2rem;
  font-family: 'IBM Plex Sans', sans-serif;
  text-transform: uppercase;
  text-align:center;

`;

const Subtitle = styled.p`

  font-size: 1rem;
  font-family: 'IBM Plex Sans', sans-serif;
  color:#c9c7c6;
`;

const Button = styled.button`
  background: none;
  border: none;
  text-align: center;
  font-size: 1rem;
  color: #c9c7c6;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 0.8rem 1.8rem;
  border-radius: 2rem;
  transition: 600ms;
  cursor: pointer;
  :hover{
    text-shadow: #c9c7c6 0px 0px 10px;

  }
  
`;

const ContenedorParte = styled.div`
  flex: 1; /* Esto hace que cada div ocupe 1/3 de la altura del Card */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
`;


const CardAboutMe = ({ title, subtitle, boton }) => {
  return (<>
    <Container>
      <Card>
        <ContenedorParte>
          <Title>{title} </Title>
        </ContenedorParte>
        <ContenedorParte>
          <Subtitle>{subtitle}</Subtitle>
        </ContenedorParte>
        <ContenedorParte>
          <Button>{boton}</Button>
        </ContenedorParte>
      </Card>
    </Container>


  </>
  )
}

export default CardAboutMe

