import hacker from "../../Assets/hacker.png"
import key from "../../Assets/key.png"
import cybersecurity from "../../Assets/cybersecurity.png"
import router from "../../Assets/router.png"
import code from "../../Assets/code.png"
import docker from "../../Assets/docker.png"

const Proyectos = [
    {
        titulo: "Cybersecurity tips for the Clueless ",
        url: "",
        imagen: cybersecurity,
        descripcion: " Friendly introduction to staying safe online. Learn practical tips for protecting yourself, from strong passwords to avoiding scams. No tech background needed",
        tecnologias: []
    }, {
        titulo: "How to configure your router",
        url: "",
        imagen: router,
        descripcion: "This guide will teach you how to configure your router, secure your network, and optimize your internet connection.",
        tecnologias: []
    }, {
        titulo: "Dark Web Done Right: Tails OS Guide",
        url: "",
        imagen: hacker,
        descripcion: "This guide explains how to set up Tails, maintain your privacy, and ensure anonymity while browsing. Perfect for those who want a secure and uncensored internet experience.",
        tecnologias: []
    }, {
        titulo: "Password Generator",
        url: "",
        imagen: key,
        descripcion: "A simple password generator written in Python that creates strong, random passwords. This project demonstrates how to generate secure passwords to protect your online accounts effectively.",
        tecnologias: []
    }, {
        titulo: "Dockers",
        url: "",
        imagen: docker,
        descripcion: "Explore what Docker is and how it works to simplify software deployment. This project explains Docker containers, their benefits, and how they help create isolated environments for easier application management and scalability.",
        tecnologias: ["react", "mysql", "expressJs"]
    },
    {
        titulo: "Web Development Projects",
        url: "",
        imagen: code,
        descripcion: "My collection of web development work. Each project highlights different skills, from front-end design to back-end programming. Explore the variety of technologies I've used, including React and Node.js. Perfect for those curious about my journey as a web developer.",
        tecnologias: ["react", "mysql", "expressJs"]

    }



]
export default Proyectos;